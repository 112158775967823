import React from 'react';
// import logo from './logo.svg';

import Main from './components/main/main.component.jsx';
import './App.css';

function App() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
